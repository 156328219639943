export default {
  install: function install(options = {}) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = "ze-snippet";
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=" + options.key;

    const first = document.getElementsByTagName("script")[0];
    first.parentNode.insertBefore(script, first);

    script.onload = () => {
      zE("messenger", "loginUser", function (callback) {
        callback(options.token);
      });
      zE("messenger:set", "locale", options.locale);
    };
  },

  logout: function install(options = {}) {
    if (typeof zE === 'undefined') {
      return;
    }
    zE("messenger", "logoutUser");
    zE("messenger", "hide");
  }
};