import Vue from "vue";
import i18n from "@/lang";
import Zendesk from "@/plugins/zendesk";

export default {
  namespaced: true,
  state: {
    userData: null,
    loggedIn: localStorage.getItem("loggedIn") != null
  },
  getters: {
    loggedIn: state => state.loggedIn,
    isGuest: state => !state.loggedIn,
    error: state => state.error,
    appUserId: state => appType => {
      return state.userData["app_users_id"][appType] ?? null;
    },
    /**
     * Массив с apptypes. Например, ['fd1', 'fd2']
     */
    apps: state => {
      return Object.keys(state.userData["app_users_id"]);
    }
  },
  mutations: {
    setLogout(state) {
      state.loggedIn = null;
    },
    setUserData(state, data) {
      state.userData = data;
    },
    addAppUserId(state, { app, userId }) {
      state.userData["app_users_id"][app] = userId;
    }
  },
  actions: {
    login(module, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/auth/login", {
            email: data.email,
            password: data.password,
            captcha: data.captcha
          })
          .then(response => {
            localStorage.setItem("loggedIn", "true");
            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    autoLogin({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/auth/auto-login/from", {
            token: data.token
          })
          .then(() => {
            localStorage.setItem("loggedIn", "true");
            resolve();
          })
          .catch(error => {
            // Обработка RenderableException
            if (error.response.status === 400) {
              dispatch(
                "app/showDialog",
                {
                  type: "error",
                  title: i18n.t("common.error"),
                  message: error.response.data.error ?? i18n.t("common.try_later")
                },
                { root: true }
              );
            }
            reject(error.response);
          });
      });
    },

    signUserUp({ commit }) {
      commit("setLoading", true);
      commit("clearError");
    },

    logOut({ commit }) {
      localStorage.removeItem("loggedIn");
      commit("setLogout");
      Zendesk.logout();
      Vue.axios.post("/auth/logout");
    },

    loadUserData({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/user")
          .then(response => {
            commit("setUserData", response.data);
            dispatch("lang/storeLanguage", response.data.lang, { root: true });

            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    setUserLang({ commit }, lang) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/langs/change", {
            lang: lang
          })
          .then(response => {
            commit("setUserData", response.data);

            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    /**
     * Активация партнерской программы для переданного app
     */
    activateProgram({ commit, dispatch }, app) {
      return Vue.axios
        .post("/activate-program", {
          pid: app
        })
        .then(response => {
          if (response.data.success) {
            dispatch(
              "app/showDialog",
              {
                type: "success",
                title: "Success",
                message: response.data.message
              },
              { root: true }
            );
            let userId = response.data.user_id;
            commit("addAppUserId", { app, userId });
            return true;
          } else {
            dispatch(
              "app/showDialog",
              {
                type: "error",
                title: "Error",
                message: response.data.message ? response.data.message : "Failed activating program"
              },
              { root: true }
            );
            return false;
          }
        })
        .catch(() => {
          dispatch(
            "app/showErrorDialog",
            {
              type: "error",
              title: "Error",
              message: "Failed activating program"
            },
            { root: true }
          );
          return false;
        });
    },

    /**
     * Логин на приложения линейки FD
     */
    loginToApp(module, { app }) {
      Vue.axios.post(`/auth/auto-login/to/${app}`).then(response => {
        let form = document.createElement("form");
        form.target = "_blank";
        form.action = response.data.url;
        form.method = "POST";
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "token";
        input.value = response.data.token;
        form.appendChild(input);
        document.body.append(form);
        form.submit();
      });
    }
  }
};
