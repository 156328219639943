<template>
  <div v-if="userDataLoaded">
    <router-view />

    <ca-dialog />
    <ca-confirm-dialog />
    <security-form-dialog />

    <snackbar />
  </div>
</template>

<script>
import CaConfirmDialog from "@/views/components/ConfirmDialog";
import SecurityFormDialog from "@/views/components/SecurityFormDialog";
import CaDialog from "./views/components/Dialog";
import Snackbar from "./views/partials/snackbar";
import Zendesk from "./plugins/zendesk";

export default {
  name: "App",
  components: {Snackbar, CaDialog, SecurityFormDialog, CaConfirmDialog },
  metaInfo() {
    return {
      title: "",
      titleTemplate: "%s | " + this.$t("titles.site_name")
    };
  },

  computed: {
    userDataLoaded() {
      return !this.$store.getters["auth/loggedIn"] || this.$store.state.auth.userData !== null;
    },
    userData() {
      return this.$store.state.auth.userData;
    }
  },

  created() {
    // Пользователь
    if (this.$store.getters["auth/loggedIn"]) {
      this.$store.dispatch("auth/loadUserData").then(userData => {
        if (!userData.is_active) {
          this.$store.dispatch('auth/logOut');
          this.$router.push({name: 'login'});
          return;
        }

        this.setLanguage(userData.lang);
        Zendesk.install({
          key: userData.helpdesk.key,
          token: userData.helpdesk.authToken,
          locale: userData.lang
        });
      });
      return;
    }

    // Гость
    if (this.$store.state.lang.language) {
      this.setLanguage(this.$store.state.lang.language);
    } else {
      const defaultLanguage = navigator.language ?? "en-US";
      this.setLanguage(defaultLanguage.split("-")[0] ?? "en");
    }
  },

  methods: {
    setLanguage(lang) {
      this.setLocale(lang);
    }
  }
};
</script>
